import React from "react";
import YouTube from 'react-youtube';

import Header from '../../header';
import Footer from '../../footer';
import ApplicationFormSummer from '../../components/forms/ApplicationFormSummer';
import LoadingSpinner from '../../components/spinner/LoadingSpinner';

import {getCampArticles, getCampIntroduction, loadCampsDataAndRender} from './CampsUtil';

export default class SummerCamps extends React.Component {

  constructor(props) {
   super(props);
    this.state = {
      photoUrl: undefined,
      campsData: [],
      photoGallery: [],
      promoPhotos:[],
      isLoading: true
    };
  }

  componentDidMount(){
      loadCampsDataAndRender("summer_camps", this);
  }

  render() {
    const {campsData, isLoading, isIntroLoading, photoUrl, promoPhotos, photoGallery} = this.state;
    const hideHeaderAndFooter = this.props.hideHeaderAndFooter;
    let campIntroduction = undefined;
    let isPhotoGalleryLoading = true;
    let isPromoLoading = true;

    if(campsData.length > 0) {
      campIntroduction = campsData[0];

      // photo galleries
      let numberOfGalleriesToLoad = campIntroduction.number_of_galleries !== undefined ? campIntroduction.number_of_galleries : 0;
      isPhotoGalleryLoading = (photoGallery.length < numberOfGalleriesToLoad);
      console.log("Number of photoGallery to load: " + numberOfGalleriesToLoad);
      console.log("Loaded photoGalleries: " + photoGallery.length);

      //promo photos
      let numberOfPromosToLoad = campIntroduction.number_of_promo_photos !== undefined ? campIntroduction.number_of_promo_photos : 0;
      isPromoLoading = (promoPhotos.length < numberOfPromosToLoad);
      console.log("Number of promo ohotos to load: " + numberOfPromosToLoad);
      console.log("Loaded promo photos: " + promoPhotos.length);
    }

    const opts = {
     height: '430',
     width: '100%',
     playerVars: { // https://developers.google.com/youtube/player_parameters
       autoplay: 0
     }
    };

    let loading = isLoading || isIntroLoading || isPhotoGalleryLoading || isPromoLoading

    return loading ? (
            <LoadingSpinner loadingState={loading} />
        ) : (
      <div>
           {hideHeaderAndFooter ? '' : <Header />}
           <div className="view textArticle camps">


              {getCampIntroduction(campsData[0], photoUrl, promoPhotos)}
              <p>
                Plán na tento rok je jasnej!! Zapište si to do kalendářů, pověste na lednice, dejte upomínku do mobilu a kdo ví co ještě…
              </p>
              <p>
                U takového přísunu zábavy, chytání a sdílení času s přáteli totiž nesmíte chybět :)) S rostoucím zájmem jsme prodloužili termíny, ať můžeme prožít chvíle brankářské radosti ještě déle. Kapacita není nafukovací. Proto neváhejte a přihlašujte své ratolesti co nejdříve, ať o to nepřijdou. Už teď se těšíme.
              </p>

              <p>
                Kemp 7.7. - 10.7. -> nástup cca 14 - 15:30 a odjezd po obědě :). Kluci i holky bez věkového omezení.
                <br/>
                <a href='https://just4keepers.dtap.cz/register/?link=23'>Přihláška zde - Luka nad Jihlavou 7. - 10.7.2025 - 5000,-</a>
              </p>
              <p>
                Při turnusu od 20.7. - 25.7. si nechte místo na rukavice, které jsou v ceně :). Kluci i holky bez věkového omezení.
                <br/>
                <a href='https://just4keepers.dtap.cz/register/?link=22'>Přihláška zde - Libice nad Doubravou 20. - 25.7.2025 - 7500,-</a>
              </p>
              <p>
              Kobylí na Moravě - kousek u Bořetic od 4. - 8.8. Pro kluky i holky od ročníku 2010 a mladší - nástup od 15h a odjezd po obědě :).
                <br/>
                <a href='https://just4keepers.dtap.cz/register/?link=21'>Přihláška zde - Bořetice 4. - 8.8. 2025 - 6450,- </a>
              </p>

              <p>
                <b>Vše potřebné se dozvíte na této stránce. Za letní kempy poprosíme o platbu na účet: 90949622/5500.</b>
              </p>


               <h4>Online přihlášky viz odkazy v textu výše</h4>
               <div>

                   {getCampArticles(campsData, photoGallery)}

                   <div className="responsive left">
                     {
                       campIntroduction.videoYoutubeIds.map((videoId, index) => (
                          <div className={"column-" + ((index % 3 ) + 1) }>
                           <YouTube
                             videoId={videoId}
                             opts={opts}
                             onReady={this._onReady}
                             />
                         </div>
                       ))
                      }
                   </div>

               </div>
           </div>

            {hideHeaderAndFooter ? '' : <Footer />}
      </div>

    );
  }
}
