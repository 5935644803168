import React, { Component } from "react";

import Header from '../header';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import '../css/slider-animations.css';
import '../css/styles.css';

import {NavLink} from "react-router-dom";
import LoadingSpinner from '../components/spinner/LoadingSpinner';

// Popup
import SimplePopup from '../simplePopup';
import SimplePopupFixedSize from '../simplePopupFixedSize';

//Footer
import Footer from '../footer';

// Articles
import MainArticleData from '../components/data/MainArticleData';
import {loadNewsAndRender} from './news/NewsUtil';

// Components
import FamilyMemberNavigation from './homepage/FamilyMemberNavigation';
import NavigationImages from './homepage/NavigationImages';


class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      newsData: [],
      photoGallery: [],
      welcomePhotos: [],
      isLoading: true
    };
  }

  componentDidMount(){
      const maximumNewsCount = 5;
      loadNewsAndRender(this, maximumNewsCount);
  }


  render() {
    const maximumSlidersCount = 1; // TODO will be changed later
		const articleData = new MainArticleData();
		const articles = articleData.getArticleDetails();

    const {newsData, isLoading, welcomePhotos} = this.state;

    let isWelcomePhotoLoading = true;

    // dynamic loading of news from database
    if(!isLoading) {
      isWelcomePhotoLoading = newsData.length < welcomePhotos.length;
    }

    let loading = isLoading || isWelcomePhotoLoading;
    console.log("isWelcomePhotoLoading= " + isWelcomePhotoLoading + " isLoading= " + isLoading);

    return loading ? (
            <LoadingSpinner loadingState={loading} />
        ) : (

       <div className="page">
				 	<Header />

          <SimplePopup/>
          <SimplePopupFixedSize />

		<Slider className="slider-wrapper main-sliders slide" autoplay="3000" touchDisabled={true}>
			{articles.slice(0, maximumSlidersCount).map((item, index) => (
				<div
					key={index}
					className="slider-content"
					style={{ background: `url('${item.sliderImage}') no-repeat center center` }}
				>
					<div className="inner">
						<h1>{item.title}</h1>
						<p>{item.sliderDescription}</p>
					</div>

				</div>
			))}
		</Slider>

    <FamilyMemberNavigation />

    <div
      key="main_page_try_training"
      className="slider-wrapper slider-content slide slide-try-training current "
      style={{ background: `url('${articleData.getTryTrainingImage()}') no-repeat center center` }}
    >
      <div className="inner">
        <h1>Vyzkoušej trénink</h1>
        <p>Vyber lokalitu a zkus si trénink zdarma. Pro kluby: objednej si ukázkový trénink zdarma.</p>
        <button>
          <NavLink to= "/try-training">
            Více informací
          </NavLink>
        </button>
        </div>
    </div>

    <div
      key="main_page_about_us"
      className="slider-wrapper slider-content slide slide-about main-about current "
      style={{ background: `url('${articleData.getAboutUsImage()}') no-repeat center center` }}
    >
      <div className="inner">
        <h1>O nás</h1>
        <p>Kdo jsme, v co věříme a k čemu vedeme.</p>
        <button>
          <NavLink to= "/about">
            Číst dále
          </NavLink>
        </button>
        </div>
    </div>

    <NavigationImages />

		<Footer />
	</div>
    );
  }
}

export default Home;
