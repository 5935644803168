import React, { useState } from 'react';


const SimplePopup = () => {
  const [isOpen, setIsOpen] = useState(true); // useState(true); to activate popup
  // change Image in styles.css

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      {isOpen && (
        <div className="popup ">
				<button className="popup-button">
					<a href="https://just4keepers.dtap.cz/register/?link=51">Přihláška</a>
				</button>

          <button className="popup-close-button left" onClick={togglePopup}>X</button>

        </div>
      )}
    </div>
  );
};

export default SimplePopup;
