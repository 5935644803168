import React from "react";

import Header from '../../header';
import Footer from '../../footer';

import {getCampIntroduction, loadCampsDataAndRender, loadPhotoGalleryImages, getPhotosFromGallery} from './CampsUtil';
import PhotoGallery from '../../components/PhotoGallery';
import LoadingSpinner from '../../components/spinner/LoadingSpinner';
//import ApplicationFormSilvester from '../../components/forms/ApplicationFormSilvester';

const eventName = "Silvestrovské chytání";

export default class SilvesterDay extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      photoUrl: undefined,
      campsData: [],
      photoGallery: [],
      isLoading: true
    };
  }

  componentDidMount(){
      loadCampsDataAndRender("silvester_camps", this);
      loadPhotoGalleryImages("silvester_camps", eventName, this);
  }

  render() {
    const {campsData, isLoading, isIntroLoading, photoUrl, photoGallery} = this.state;
    const hideHeaderAndFooter = this.props.hideHeaderAndFooter;

    let campIntroduction = undefined;
    let isPhotoGalleryLoading = true;

    if(campsData.length > 0) {
      campIntroduction = campsData[0];
      let numberOfGalleriesToLoad = campIntroduction.number_of_galleries !== undefined ? campIntroduction.number_of_galleries : 0;
      isPhotoGalleryLoading = (photoGallery.length < numberOfGalleriesToLoad);
      console.log("Number of photoGallery to load: " + numberOfGalleriesToLoad);
      console.log("Loaded photoGalleries: " + photoGallery.length);
    }

    let loading = isLoading || isIntroLoading || isPhotoGalleryLoading

    return loading ? (
            <LoadingSpinner loadingState={loading} />
        ) : (
      <div>
            {hideHeaderAndFooter ? '' : <Header />}
		        <div className="view textArticle camps">

              {getCampIntroduction(campIntroduction, photoUrl)}

              <p>
              Pojďme si ke konci roku ještě pořádně zaskákat, zachytat, zastřílet, hrát si a bavit se... prostě se rozloučit s rokem 2024 po golmansku.
              </p>

              <p>
              Přihlas se co nejdřív - čas běží a místa mizí.
              </p>

              <p>
              Cena: 900 Kč
              </p>

              <p>
              Dva tréninky toho nejlepšího co umíme
              </p>

              <p>
              Oběd, svačina, pitný režim
              </p>

              <p>
              J4K dětské špunty
              </p>

              <p>
              Více informací na tel. 731 575 400 nebo na just4keeperscz@gmail.com
              </p>

<p>
<h4> <a href='https://just4keepers.dtap.cz/register/?link=51'>Přihláška zde</a></h4>

</p>


              <PhotoGallery photos={getPhotosFromGallery(eventName, photoGallery)}/>

            </div>

            {hideHeaderAndFooter ? '' : <Footer />}
      </div>
    );
  }

}
